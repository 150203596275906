///
/// Solid State by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Footer */

#footer {
  .inner {
    @include padding(5em, 0);
    @include vendor('display', 'flex');
    @include vendor('flex-direction', 'row');
    @include vendor('flex-wrap', 'wrap');
    margin: 0 auto;
    width: _size(inner);

    > * {
      width: 100%;
    }

    form {
      margin: 0 _size(section-spacing, large) 0 0;
      width: calc(50% - #{_size(section-spacing, large) * 0.5});
    }

    .contact {
      width: calc(50% - #{_size(section-spacing, large) * 0.5});
    }

    .copyright {
      border-top: solid 2px _palette(border);
      list-style: none;
      margin: (_size(element-margin) * 2) 0 _size(element-margin) 0;
      padding: _size(element-margin) 0 0 0;
      width: 100%;

      li {
        border-left: solid 2px _palette(border);
        color: _palette(fg-light);
        display: inline-block;
        font-size: 0.9em;
        line-height: 1;
        margin-left: 1em;
        padding: 0;
        padding-left: 1em;

        &:first-child {
          border-left: 0;
          margin-left: 0;
          padding-left: 0;
        }

        a {
          color: inherit;
        }
      }
    }
  }

  @include breakpoint('<=large') {
    background-color: _palette(bg);

    background-image: linear-gradient(
        to top,
        transparentize(_palette(bg), 0.2),
        transparentize(_palette(bg), 0.2)
      ),
      url('../../images/bg.jpg');

    background-size: auto, cover;

    background-position: center, center;

    margin-top: (_size(wrapper-edges, large) * -1);
    padding-top: _size(wrapper-edges, large);
  }

  @include breakpoint('<=medium') {
    margin-top: (_size(wrapper-edges, medium) * -1);
    padding-top: _size(wrapper-edges, medium);

    .inner {
      @include padding(3em, 3em);
      display: block;
      width: 100%;

      form {
        width: 100%;
        margin: 0 0 (_size(element-margin) * 2) 0;
      }

      .contact {
        width: 100%;
        margin: 0 0 (_size(element-margin) * 2) 0;
      }

      .copyright {
        margin: (_size(element-margin) * 2) 0 _size(element-margin) 0;
      }
    }
  }

  @include breakpoint('<=small') {
    margin-top: (_size(wrapper-edges, small) * -1);
    padding-top: _size(wrapper-edges, small);

    .inner {
      @include padding(2em, 2em);

      form {
        margin: 0 0 (_size(element-margin) * 1.5) 0;
      }

      .contact {
        margin: 0 0 (_size(element-margin) * 1.5) 0;
      }
    }
  }

  @include breakpoint('<=xsmall') {
    .inner {
      .copyright {
        li {
          border-left: 0;
          display: block;
          margin: 1em 0 0 0;
          padding-left: 0;

          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }

  @include breakpoint('<=xxsmall') {
    .inner {
      @include padding(2em, 1.5em);
    }
  }
}
