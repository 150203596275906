///
/// Solid State by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Menu */

#page-wrapper {
  @include vendor('transition', 'filter 0.25s ease');
}

#menu {
  @include vendor('align-items', 'center');
  @include vendor('display', 'flex');
  @include vendor('justify-content', 'center');
  @include vendor('pointer-events', 'none');
  @include vendor(
    'transition',
    ('opacity #{_duration(menu)} ease', 'visibility #{_duration(menu)}')
  );
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: transparentize(_palette(bg), 0.2);
  cursor: default;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  text-align: center;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 1001;

  .inner {
    @include padding(2.5em, 1.5em);
    @include vendor('transform', 'translateY(0.5em)');
    @include vendor(
      'transition',
      ('opacity #{_duration(menu)} ease', 'transform #{_duration(menu)} ease')
    );
    -webkit-overflow-scrolling: touch;
    background: _palette(accent);
    border-radius: _size(border-radius);
    display: block;
    max-width: 100%;
    opacity: 0;
    position: relative;
    width: 18em;
  }

  h2 {
    border-bottom: solid 2px _palette(border);
    padding-bottom: 1em;
  }

  .close {
    background-image: url('../../images/close.svg');
    background-position: 75% 25%;
    background-repeat: no-repeat;
    background-size: 2em 2em;
    border: 0;
    content: '';
    display: block;
    height: 4em;
    overflow: hidden;
    position: absolute;
    right: 0;
    text-align: center;
    text-indent: 4em;
    top: 0;
    width: 4em;
  }

  .links {
    list-style: none !important;
    margin-bottom: (_size(element-margin) - 0.5em);
    padding: 0;

    li {
      padding: 0;

      a {
        border-radius: _size(border-radius);
        border: 0;
        display: block;
        font-family: _font(family-heading);
        font-size: 0.8em;
        font-weight: _font(weight-heading);
        letter-spacing: _font(kern-heading);
        line-height: 1.85em;
        padding: 0.75em 0;
        text-transform: uppercase;

        &:hover {
          background: saturate(darken(_palette(accent), 3), 1.5);
        }
      }
    }
  }

  @include breakpoint('<=small') {
    .inner {
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      .close {
        background-size: 1.5em 1.5em;
      }
    }
  }
}

.main-body {
  .is-menu-visible {
    #page-wrapper {
      @include vendor('filter', 'blur(1.5px)');
    }

    #menu {
      @include vendor('pointer-events', 'auto');
      opacity: 1;
      visibility: visible;

      .inner {
        @include vendor('transform', 'translateY(0)');
        opacity: 1;
      }
    }
  }
}
