@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Raleway:200,700|Source+Sans+Pro:300,600,300italic,600italic');

/*
	Solid State by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

// Breakpoints.

@include breakpoints(
  (
    xlarge: (
      1281px,
      1680px,
    ),
    large: (
      981px,
      1280px,
    ),
    medium: (
      737px,
      980px,
    ),
    small: (
      481px,
      736px,
    ),
    xsmall: (
      361px,
      480px,
    ),
    xxsmall: (
      null,
      360px,
    ),
  )
);

// Base.

@import 'base/reset';
@import 'base/page';
@import 'base/typography';

// Component.

@import 'components/row';
@import 'components/section';
@import 'components/form';
@import 'components/box';
@import 'components/icon';
@import 'components/image';
@import 'components/list';
@import 'components/actions';
@import 'components/icons';
@import 'components/contact';
@import 'components/pagination';
@import 'components/table';
@import 'components/button';
@import 'components/features';

// Layout.

@import 'layout/header';
@import 'layout/banner';
@import 'layout/wrapper';
@import 'layout/footer';
@import 'layout/menu';
